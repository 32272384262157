html {
  font-size: 62.5%;
  width:100%;
  margin:0;
  padding: env(safe-area-inset);
}

body {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}
