.App {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  background: url(./assets/imgs/background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.title {
  font-size: 3.7rem;
  font-family: 'Cormorant', serif;
  user-select:none;
  -webkit-user-select:none;
  text-align: center;
}

input {
  padding: 5px;
}

.nasheedBoard {
  margin: 1em auto;
  width: 80vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
}

.nasheed-buttons {
  margin: 10px;
}


/* CSS */
.button-64 {
  appearance: none;
  background-color: rgba(255, 255, 255, 0.7);
  border-width: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: Clarkson,Helvetica,sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 0.5em;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 1.0em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1),background-color 100ms cubic-bezier(.694, 0, 0.335, 1),color 100ms cubic-bezier(.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-64:before {
  animation: opacityFallbackOut .5s step-end forwards;
  backface-visibility: hidden;
  background-color: #EBEBEB;
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
  width: 100%;
}

.button-64:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.button-64:after {
  background-color: #FFFFFF;
}

.button-64 span {
  z-index: 1;
  position: relative;
}

textarea:focus, input:focus{
  outline: none;
}
@media screen and (min-width: 1400px){
	.title {
    font-size: 3vw;
  }
  .button-64 {
    font-size: 1.3vw;
  }
}