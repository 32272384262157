*, *::before, *::after {
    box-sizing: border-box;
}

body {
    background-color: #f3f3f3;
    margin: 0;
    overflow: hidden;
}

.wrapper {
    display: flex;
    justify-content: space-around;
}

.container {
    width: 8.5in;
    max-height: 83vh;
    overflow: auto;
    padding: 0in 1in;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
    background-color: white;
}

.edit-buttons {
    display: flex;
    flex-direction: column;
}

.edit-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }

  .edit-title p {
    margin: 0;
    font-family: 'Cormorant', serif !important;
  }

  textarea {
    font-size: 1.5rem;
    font-family: 'Cormorant', serif;
    margin: 1rem;
    text-align: center;
    border: none;
    padding: 0;
    display: block;
    min-width: 8.5in;
    width: 100%;
    overflow: hidden;
    border: none;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;

  }

  .arab-text {
    margin: 13px 0;
  }

  .non-arab-text {
    margin: 0px auto;
  }

  .edit-title textarea {
    font-size: 3rem;
    font-family: 'Cormorant', serif;
    margin: 0;
    text-align: center;
    border: none;
    resize: none;
    padding: 0;
  }

  .mui-button {
    font-size: 14px !important;
  }

.body {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 1.5rem;
  margin-top: 10px;
}

.alert {
    background-color: #ffffd8;
    width: fit-content;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    position: absolute;
    top: 0;
    right: 0;
}

@media screen and (max-width: 850px) {

    .mui-button {
        font-size: 8px !important;
        min-width: 50px !important;
        padding: 6px !important;
    }

    .edit-buttons {
        margin: 0 5px;
    }
    .container {
        padding: 0;
        width: 70vw;
    }

    .edit-title p {
        font-size: 1.8rem;
        font-family: 'Cormorant', serif !important;
    }

    .edit-title textarea {
        font-size: 1.8rem;
        font-family: 'Cormorant', serif !important;
    }

    .body div p {
        font-size: 1.8rem;
        font-family: 'Cormorant', serif !important;
    }

    .body div textarea {
        font-size: 1.8rem;
        min-width: 220px;
        margin: 5px 0;
        font-family: 'Cormorant', serif !important;
    }

    .modal-confirm {
        width: 350px !important;
    }

}

@media screen and (max-height: 600px) {
    textarea {
        font-size: 1.4rem;
        min-width: 8.5in !important;
        font-family: 'Cormorant', serif !important;
    }
    .container {
        padding: 0;
        width: 80vw !important;
    }

}

@media screen and (min-width: 1550px){
	.edit-title p {
        font-size: 5.3rem;
        font-family: 'Cormorant', serif !important;
    }

    .edit-title textarea {
        font-size: 5.3rem;
        font-family: 'Cormorant', serif !important;
    }
	
    .body div p {
        font-size: 4.3rem;
        font-family: 'Cormorant', serif !important;
    }
    
    .container .body div textarea {
        font-size: 4.3rem;
        font-family: 'Cormorant', serif !important;
    }

    .container {
        padding: 0;
        width: 80vw !important;
    }

    .modal-confirm {
        width: 50vw !important;
    }
    .modal-confirm-text {
        font-size: 5rem !important;
        margin-bottom: 50px !important;
    }

    .mui-button {
        font-size: 28px !important;
        min-width: 90px !important;
        padding: 20px 60px !important;
    }

}
