.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 1000;
}
.modal {
	color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: linear-gradient(rgb(2, 16, 28), rgb(2, 16, 25));
	background-size: cover;
	padding: 50px;
	z-index: 1000;
	width: 80vw;
  	height: 85vh;
  	border-radius: 1.5em;
	box-sizing: content-box;
  	border-width: 8px;
  	border-style: solid;
  	border-image: linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C);
  	border-image-slice: 1;
	box-shadow: rgba(0,0,0,0.35) 0em 0.3em 0.9em;
	display: flex;
	flex-direction: column;
	padding: 1.5em;
	font-family: 'Cormorant', sans-serif;
	user-select:none;
	-webkit-user-select:none;
}

.modal .title{
  display: inline-block;
  text-align: center;
  margin-top: -2em;
  font-size: 1.5rem;
}

.modal .body {
  flex: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  text-align: center;
  padding-bottom: 1.5rem;
}
.modal .body p {
  margin: 1vh;

}

.modal .body .paragraph {
  margin: auto;

}
.arabText {
	font-weight: 300;
	word-spacing: 0.5rem;
}
.engText {
	font-weight: 500;
}
@media screen and (max-width: 850px) {
	/* .modal .title {
		font-size: 0.6rem;
	}
	.modal .body {
		font-size: 1rem;
	} */
	.modal {
		height: 70vh;
	}
	.modal .title {
		font-size: 1rem;
	}
	.modal .body {
		font-size: 1.8rem;
	}
}
 /* @media (max-height: 550px) {
	

} */
 

/* @media (min-height: 900px) {
	.modal .title {
		font-size: 1.7vw;
	}
	.modal .body {
		font-size: 3.2vw;
	}
} */
@media screen and (max-height: 600px) {
	/* .modal .title {
		font-size: 2.4vh;
	}
	.modal .body {
		font-size: 4.0vh;
	} */
	.modal {
		height: 60vh;
		padding-bottom: 3rem;
	}
	.modal .title {
		margin-top: -3.0em;
		font-size: 0.8rem;
	}
	.modal .body {
		font-size: 1.4rem;
	}
	.modal .body .engText {
		font-size: 1.3rem;
	}
}
@media screen and (min-width: 1400px){
	.modal .title {
		font-size: 1.1vw;
	}
	.modal .body {
		font-size: 2.4vw;
	}
}
